/**
 * i18n configuration
 */
export default defineI18nConfig(() => ({
  legacy: false,
  defaultLocale: 'de-DE',
  fallbackLocale: 'de-DE',
  allowComposition: true,
  globalInjection: true,
  locales: [
    {
      code: 'de-DE',
      iso: 'de-DE',
      file: 'de-DE.ts',
    },
    {
      code: 'en-GB',
      iso: 'en-GB',
      file: 'en-GB.ts',
    },
  ],
}));
